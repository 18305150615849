.product-detail {
}

.product-top-image {
  text-align: center;
  width: 100%;
  height: 350px; /* Set the height for larger screens */
  overflow: hidden; /* Ensure any overflow content is hidden */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.product-top-image img {
  width: 100%; /* Ensure the image fits the width */
  height: 100%; /* Ensure the image fits the height */
  object-fit: cover; /* Cover the area without stretching */
}

.product-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.product-images img {
  max-width: 100%;
  height: auto;
  flex: 1 1 calc(33.333% - 10px);
  border-radius: 30px;
}

.product-description {
  padding-left: 20px;
  padding-bottom: 20px;
}

.product-description h2 {
  margin-top: 0;
}

.product-description p {
  margin: 10px 0;
}

.desc {
  font-size: large;
  justify-content: center;
  align-items: left;
}

.tum {
  margin-top: 100px;
  margin-bottom: 100px;
}

/* Media Queries for Responsive Design */

/* Large desktops and laptops */
@media (max-width: 1200px) {
  .product-top-image {
    height: 480px; /* Adjust height for laptops */
  }
}

/* Tablets */
@media (max-width: 768px) {
  .product-top-image {
    height: 360px; /* Adjust height for tablets */
  }

  .product-images img {
    flex: 1 1 calc(50% - 10px); /* Adjust to two columns for tablets */
  }
}

/* Mobile devices */
@media (max-width: 576px) {
  .product-top-image {
    height: 240px; /* Adjust height for mobile */
  }

  .product-images img {
    flex: 1 1 100%; /* Adjust to single column for mobile */
  }

  .product-description {
    padding: 10px; /* Reduce padding for mobile */
  }

  .desc {
    font-size: medium; /* Adjust font size for mobile */
  }
}
