/* Reset margin for homepage */
.homepage {
  margin: 0;
  padding: 0;
}

/* Ensure the container fluid takes up full width without extra padding */
.container-fluid {
  padding: 0;
  margin: 0;
}

/* Cover image styling */
.cover-image {
  width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio and scale with the width */
  max-height: 70vh; /* Set max height relative to viewport */
  object-fit: cover; /* Ensures the image covers the container without distortion */
  margin: 0;
  padding: 0;
}

/* Full-width column reset padding */
.full-width-col {
  padding: 0;
  margin: 0;
}

/* Body styling */
body {
  background-color: #f0fdfa !important;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

/* Video styling for the cover video */
.cover-video {
  width: 100%; /* Full width */
  height: auto; /* Let the height adjust with the width */
  max-height: 70vh; /* Adjust height to match cover image */
  object-fit: cover; /* Ensure video covers the container without distortion */
  opacity: 0.7;
  margin: 0;
  padding: 0;
}

/* Carousel container */
.carousel-container {
  width: 100%; /* Make it full width */
  padding: 0 5%; /* Add padding relative to width */
  margin: 50px auto; /* Center it with smaller margins */
}

/* Carousel image */
.carousel-image {
  width: 100%; /* Full width of the container */
  height: auto; /* Set height to auto for better scaling */
  object-fit: contain; /* Ensure image scales without cutting */
}

/* Responsive design for small screens */
@media (max-width: 768px) {
  .carousel-image {
    height: auto; /* Let height adjust automatically */
    object-fit: cover; /* Ensure image fills container without cutting */
  }

  .cover-image,
  .cover-video {
    max-height: 50vh; /* Reduce cover image and video height on smaller screens */
  }

  .carousel-container {
    margin: 20px auto; /* Reduce margin for smaller devices */
    padding: 0 10px; /* Adjust padding for smaller devices */
  }
}

/* For extra small devices like phones */
@media (max-width: 480px) {
  .carousel-image {
    height: auto; /* Automatically adjust height */
  }

  .carousel-container {
    margin: 10px auto; /* Reduce margin even more */
    padding: 0 5px; /* Minimize padding */
  }

  .cover-image,
  .cover-video {
    max-height: 40vh; /* Further reduce height for cover media on small devices */
  }
}
