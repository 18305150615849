.about-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0; /* Ensure no padding */
}

.tum {
  margin-top: 0; /* Set margin-top to 0 */
  margin-bottom: 100px; /* Keep bottom margin if needed */
}

.heading {
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.about-description {
  max-width: 600px;
  text-align: left;
  margin: 0; /* Remove margin */
  padding-bottom: 20px; /* Maintain bottom space */
}

.desc {
  font-size: 17px;
  margin-left: 0; /* Remove left margin */
  text-align: justify;
}

.about-video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Maintain 16:9 aspect ratio */
  overflow: hidden;
  margin-bottom: 20px;
}

.about-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.about-video:hover {
  transform: scale(1.05);
}

/* Media Queries */

/* Desktops and large screens */
@media (max-width: 1200px) {
  .about-top-image {
    height: 480px; /* Adjust height for desktops/laptops */
  }
}

/* Tablets */
@media (max-width: 768px) {
  .about-top-image {
    height: 360px; /* Adjust height for tablets */
  }

  .about-images img {
    flex: 1 1 calc(50% - 10px); /* Two columns layout for tablets */
  }

  .about-description {
    padding: 10px; /* Reduce padding */
  }
}

/* Mobile devices */
@media (max-width: 576px) {
  .about-top-image {
    height: 240px; /* Adjust height for mobiles */
  }

  .about-images img {
    flex: 1 1 100%; /* Single column for mobiles */
  }

  .about-description {
    padding: 10px; /* Reduce padding for mobile */
  }

  .desc {
    font-size: medium; /* Adjust font size for mobile */
  }
}
