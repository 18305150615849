.footer-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 0 15px; /* Padding to replace the container padding */
}

.footer-grid-item {
  padding: 15px;
  color: #fff;
}

.footer-grid-item h5 {
  border-bottom: 2px solid #9ca3af;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.footer-grid-item p,
.footer-grid-item ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-grid-item ul {
  padding-left: 0;
}

.footer-grid-item li {
  margin: 5px 0;
}

.footer-grid-item a {
  color: #fff;
  text-decoration: none;
}

.footer-grid-item a:hover {
  text-decoration: underline;
}
