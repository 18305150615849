/* Flex container for main layout */
.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  padding: 20px; /* Add padding around the flex container */
}

/* Text section styling */
.text-section {
  flex: 1; /* Text section takes up one part of the space */
  padding: 20px;
  font-size: 40px;
  line-height: 1.5; /* Enhance readability */
}

/* Card section styling */
.card-section {
  flex: 1; /* Card section takes up one part of the space */
  padding-right: 50px;
}

/* Grid container for cards */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two cards per row */
  gap: 20px; /* Space between cards */
}

/* Individual experience card styling */
.experience-card {
  border: 1px solid #ccc; /* Border for the card */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Inner padding */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: background-color 0.5s ease, color 0.5s ease, box-shadow 0.5s ease; /* Smooth hover transition */
  background-color: white;
}

/* Hover effect for experience card */
.experience-card:hover {
  background-color: #3498db; /* Change background color */
  color: white; /* Change text color */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add stronger shadow */
}

/* Responsive design for tablets */
@media (max-width: 992px) {
  .flex-container {
    flex-direction: column; /* Stack elements vertically */
    gap: 20px;
  }

  .text-section,
  .card-section {
    padding: 15px; /* Adjust padding for tablets */
  }

  .grid-container {
    grid-template-columns: repeat(1, 1fr); /* One card per row on tablets */
  }

  .experience-card {
    padding: 20px; /* Adjust padding for card on tablets */
  }
}

/* Responsive design for mobile devices */
@media (max-width: 768px) {
  .flex-container {
    flex-direction: column; /* Stack elements on mobile */
    gap: 15px;
  }

  .text-section {
    font-size: 30px; /* Adjust font size on mobile */
    padding: 10px; /* Reduce padding */
  }

  .card-section {
    padding-right: 10px; /* Reduce padding for mobile */
  }

  .grid-container {
    grid-template-columns: 1fr; /* One card per row */
  }

  .experience-card {
    padding: 15px; /* Adjust padding for smaller screen */
  }
}

/* Responsive design for small mobile devices */
@media (max-width: 480px) {
  .text-section {
    font-size: 24px; /* Further adjust font size */
    line-height: 1.4; /* Adjust line height for small screens */
    padding: 5px; /* Reduce padding for small mobile */
  }

  .experience-card {
    padding: 10px; /* Reduce padding for cards */
  }

  .grid-container {
    gap: 10px; /* Reduce gap between cards */
  }
}
