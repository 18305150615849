/* General container and row settings */
.section {
  margin-top: 30px;
  padding: 20px;
  background: linear-gradient(to bottom right, #23dcd600, #b0dbe8); /* Gradient from purple to blue */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  animation: fadeIn 1.5s forwards;
  position: relative;
}

/* Service title */
.servies {
  text-align: center;
  margin-bottom: 45px;
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50;
  animation: slideInTop 1s ease-out; /* Slide-in effect for the title */
}

/* Zigzag Layout: Image on the left for odd rows, text on the right for even rows */
.row-zigzag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 50px;
}

.row-zigzag:nth-child(even) {
  flex-direction: row-reverse; /* Reverse the order for even rows */
}

/* Image Styling with Hover Effects */
.custom-image {
  width: 100%;
  height: auto;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.custom-row {
  margin-left: 50px; /* Left margin */
  margin-right: 50px; /* Right margin */
}

/* Hover effect for image */
.custom-image:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  filter: brightness(1.1); /* Slight brightness increase */
}

/* Content Section Styling */
.section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  animation: fadeIn 1.5s forwards;
  position: relative;
}

/* Hover effect for content section */
.section:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Floating animated icon for section */
.section::before {
  content: "🔧"; /* Example: tool icon (can change) */
  position: absolute;
  font-size: 3rem;
  top: -30px;
  left: -30px;
  opacity: 0;
  animation: floatIn 1.5s ease-out forwards;
}

.img-fluid {
  margin-left: 70px;
}

/* Heading Styling */
.section h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
  transition: color 0.3s ease;
}

/* Hover effect for heading */
.section h3:hover {
  color: #007bff;
}

/* Paragraph Text Styling */
.section p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  text-align: justify;
  transition: color 0.3s ease;
}

/* Hover effect for paragraph text */
.section p:hover {
  color: #333;
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Keyframes for sliding the service title */
@keyframes slideInTop {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Floating icon effect */
@keyframes floatIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.mt-250 {
  margin-top: 50px;
}

/* Responsive Design */

/* For small desktops and large laptops */
@media (max-width: 1200px) {
  .custom-row {
    margin-left: 30px;
    margin-right: 30px;
  }

  .servies {
    font-size: 2rem;
  }
}

/* For tablets and small laptops */
@media (max-width: 992px) {
  .row-zigzag {
    flex-direction: column;
    text-align: center;
  }

  .custom-row {
    margin: 0 auto;
  }
}

/* For tablets and large phones */
@media (max-width: 768px) {
  .custom-image {
    max-width: 100%;
  }

  .section {
    padding: 15px;
  }

  .servies {
    font-size: 1.8rem;
  }

  .section h3 {
    font-size: 22px;
  }

  .section p {
    font-size: 14px;
  }
}

/* For small phones */
@media (max-width: 576px) {
  .section {
    padding: 10px;
  }

  .servies {
    font-size: 1.5rem;
  }

  .section h3 {
    font-size: 20px;
  }

  .section p {
    font-size: 13px;
  }
  
  .custom-image {
    max-width: 100%;
    margin: 0 auto; /* Center the image on small screens */
  }

  .section::before {
    font-size: 2rem;
    top: -15px;
    left: -15px;
  }
}
