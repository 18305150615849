.custom-navbar {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensures it stays above other elements */
  background-color: white; /* Ensure it has a background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better visibility */
  width: 100%; /* Ensures it spans the full width */
}

.nav-link {
  color: #333;
  margin-right: 1rem;
}

.nav-link:hover {
  color: #007bff;
}

.nav-item .dropdown-menu {
  display: none;
  margin-top: 0;
  background-color: white; /* Matches navbar background */
  border: none; /* Remove default border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow */
}

.nav-item:hover .dropdown-menu {
  display: block;
}

.dropdown-item:hover {
  background-color: #e9ecef; /* Light gray background on hover */
}

/* Responsive styling */
@media (max-width: 992px) {
  .navbar-collapse {
    background-color: white; /* Matches navbar background */
  }

  .nav-link {
    margin-right: 0;
    padding: 0.5rem 1rem;
  }

  .dropdown-menu {
    position: static; /* Ensure dropdowns open correctly in collapsed mode */
    float: none; /* Reset float */
  }
}
.dropdown-toggle {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem; /* Adjust padding as necessary */
}
